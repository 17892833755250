import type {LinksFunction} from '@remix-run/server-runtime';
import {Outlet, useMatches} from '@remix-run/react';

import {Theme} from '@/enums';
import type {Handle} from '@/types';
import Provider from '@/components/shared/Page/Provider';

export const links: LinksFunction = () => {
  return [
    {
      // FKGrotesk normal
      rel: 'preload',
      as: 'font',
      crossOrigin: 'anonymous',
      type: 'font/woff2',
      href: 'https://cdn.shopify.com/b/shopify-brochure2-assets/10b92d1baee589970c4e0c7f380635d9.woff2',
    },
    {
      // FKGrotesk bold
      rel: 'preload',
      as: 'font',
      crossOrigin: 'anonymous',
      type: 'font/woff2',
      href: 'https://cdn.shopify.com/b/shopify-brochure2-assets/345c555dd2458a8194bfb323ca4d6a7b.woff2',
    },
  ];
};

export const handle: Handle = {
  theme: Theme.Oberlo,
  enableGtm: true,
};

export default function Layout() {
  const matches = useMatches();
  let merged = {};
  for (const match of matches) {
    if (match.data) {
      merged = {...merged, ...match.data};
    }
  }

  return (
    <Provider data={merged}>
      <Outlet />
    </Provider>
  );
}
